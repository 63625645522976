import { fetchFunction } from "../fetchFunction";

export const getFormMonthlyChargeById = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/monthly/charge/${id}`, options)
}

export const getFormMonthlyChargesByMonthlyId = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/monthly/${id}/charges/all`, options)
}

export const generatePixById = async ({ id, data, type }) => {
    if (type === "charge") {
        return generatePixByChargeId({ id: id, data: data })
    } else {
        return generatePixByTicketId({ id: id, data: data })
    }
}

export const generatePixByChargeId = async ({ id, data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({
            id: id,
            ...data
        })
    }
    return await fetchFunction(`/gateway/payment/monthly/charge/pix`, options)
}

export const generatePixByTicketId = async ({ id, data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({
            id: id,
            ...data
        })
    }
    return await fetchFunction(`/gateway/payment/ticket/pix`, options)
}

export const validatePixById = async ({ id, type }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/gateway/payment/monthly/charge/${id}/pix`, options)
}

export const deletePixById = async ({ id, type }) => {
    const options = {
        method: 'DELETE'
    }
    return await fetchFunction(`/gateway/payment/monthly/charge/${id}/pix`, options)
}

export const validateCreditCardById = async ({ id, type, data }) => {
    if (type === "charge") {
        return validateCreditChargeById({ id: id, data: data })
    } else {
        return validateCreditTicketById({ id: id, data: data })
    }
}

export const validateCreditChargeById = async ({ id, data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/gateway/payment/monthly/charge/${id}/credit`, options)
}

export const validateCreditTicketById = async ({ id, data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/gateway/payment/ticket/${id}/credit`, options)
}
