import React from 'react'
import '../ticket.css'
import { PromotionImage, TicketHeader, TicketFooter } from '../components/ticket-components'

class Receipt extends React.Component {

    render() {
        return (
            <>
                <PromotionImage ticket={this.props.receipt} />
                <div className="ticket">
                    <TicketHeader estacionamento={this.props.receipt.estacionamento} />
                    <NewNoteButton newNote={this.props.receipt} />
                    <TicketFooter ticket={this.props.receipt} />
                </div>
            </>
        )
    }
}

class NewNoteButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasNote: props.newNote !== null,
            pdf_nfse: props.newNote?.pdf_nfse,
            currentStatus: props.newNote?.currentStatus,
        }
    }

    render() {
        return (
            <>
                {
                    this.state.hasNote &&
                    <>
                        {
                            this.state.currentStatus === "NOTE_PROCESSING" &&
                            <div className="alert alert-warning mt-2">
                                <span className="badge text-bg-warning text-white">Nota fiscal</span> <br />
                                <span className="mt-2">Estamos gerando a sua nota fiscal, isso pode levar até 2 dias.</span>
                            </div>
                        }
                        {
                            this.state.currentStatus === "NOTE_ERROR" &&
                            <div className="alert alert-danger mt-2">
                                <span className="badge text-bg-danger text-white">Nota fiscal</span> <br />
                                <span className="mt-2">Não foi possível gerar a sua nota fiscal, entre em contato com o suporte.</span>
                            </div>
                        }
                        {
                            this.state.currentStatus === "NOTE_CANCELED" &&
                            <div className="alert alert-danger mt-2">
                                <span className="badge text-bg-danger text-white">Nota fiscal</span> <br />
                                <span className="mt-2">Esta nota fiscal esta cancelada.</span>
                                <a href={this.state.pdf_nfse} target="_blank" rel="noreferrer" className="btn btn-danger w-100 text-white my-3">
                                    <i className="fa-solid fa-file-pdf mx-2" />
                                    Visualizar nota
                                </a>
                            </div>
                        }
                        {
                            this.state.currentStatus === "NOTE_GENERATED" &&
                            <div className="alert alert-danger mt-2">
                                <span className="badge text-bg-danger text-white">Nota fiscal</span> <br />
                                <span className="mt-2">Nota gerada com sucesso.</span>
                                <a href={this.state.pdf_nfse} target="_blank" rel="noreferrer" className="btn btn-success w-100 text-white mb-3">
                                    <i className="fa-solid fa-file-pdf mx-2" />
                                    Visualizar nota
                                </a>
                            </div>
                        }
                    </>
                }
            </>
        )
    }
}

export { Receipt, NewNoteButton }