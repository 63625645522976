import { getCurrentPark } from "../storage/parkRepository";
import { fetchFunction } from "./fetchFunction";

export const getAllActivesMonthlyPlans = async () => {
    const park = getCurrentPark()
    const options = {
        method: 'GET',
        apiKey: park.id
    }
    return await fetchFunction("/data/park-monthly-online-plans", options)
}

export const getAllVehiclesModels = async () => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction("/data/public/vehicles-models", options)
}

export const getAllVehiclesColors = async () => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction("/data/public/vehicles-colors", options)
}