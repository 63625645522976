
import React from 'react'
import { documentMask, loadClientSession, queryString } from '../../shared/utils'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { LoadingContainer } from '../../shared/layout/system-container'
import { getFormAccreditedById } from '../../config/api/form/formAccredited'

class AccreditedView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading"
        }
    }

    componentDidMount = async () => {
        const client = await loadClientSession()
        if (client) {
            this.setState({ data: client })
            this.setState({ state: "loading" })
            const c = queryString("c")
            const data = await getFormAccreditedById({ id: c })
            if (data.success) {
                this.setState({ ...data.data })
                this.setState({ state: "fill" })
            } else {
                this.setState({ errorMessage: data.message })
                this.setState({ state: "not-found" })
            }
        } else {
            this.setState({ state: "not-found" })
        }
    }

    render() {
        return (
            <div className="container-md top-20">
                {
                    this.state.data?.tema?.logo &&
                    <ImageContainer url={this.state.data?.tema?.logo} />
                }
                <RegisterContainer>
                    {this.state.state === "not-found" &&
                        <div className="alert alert-danger text-center mt-4">
                            <span className="badge text-bg-danger">Oops!</span> <p />
                            <h4>Credenciado não encontrado</h4>
                            <span>Entre em contato com o estabelecimento.</span>
                        </div>
                    }
                    {this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <div className="row mt-3">
                            <AccreditedHeaderView item={this.state} />
                        </div>
                    }
                </RegisterContainer>
            </div>
        )
    }
}

class AccreditedHeaderView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props.item,
            vehicles: props.item.accredited.vehicles ?? []
        }
    }

    render() {
        return (
            <>
                {
                    this.state.park &&
                    <div className="col-lg-12">
                        <div className="alert alert-secondary">
                            <span className="badge text-bg-secondary">Estabelecimento</span> <br />
                            <h4>{this.state.park.name}</h4>
                            {documentMask(this.state.park.document)} - {this.state.park.phone} <br />
                            {this.state.park.address.street}, {this.state.park.address.number} - {this.state.park.address.zipcode} <br />
                            {this.state.park.times}
                        </div>
                    </div>
                }
                {
                    this.state.accredited && <>
                        {
                            this.state.accredited.status === "pending" &&
                            <div className="col-lg-12">
                                <div className="alert alert-warning">
                                    <span className="badge text-bg-warning text-white">Pendente</span> <br />
                                    Este credenciado ainda não foi aprovado.
                                </div>
                            </div>
                        }
                        {
                            this.state.accredited.status === "Ativo" &&
                            <div className="col-lg-12">
                                <div className="alert alert-success">
                                    <span className="badge text-bg-success text-white">Ativo</span> <br />
                                    Credenciado com permissão de acesso.
                                </div>
                            </div>
                        }
                        {
                            this.state.accredited.status === "Inativo" &&
                            <div className="col-lg-12">
                                <div className="alert alert-danger">
                                    <span className="badge text-bg-danger text-white">Inativo</span> <br />
                                    Credenciado inativado, entre em contato com o estabelecimento caso queira re-ativar esta conta.
                                </div>
                            </div>
                        }
                        <div className="col-lg-12">
                            <h4>Credenciado</h4>
                            <strong>
                                {this.state.accredited.name}
                            </strong> <br />
                            <span>{this.state.accredited.cellphone} - {this.state.accredited.address.city}</span>
                        </div>
                        <div className="col-lg-12 mt-3">
                            <h4>Permissão</h4>
                            <span>Das {this.state.accredited.access.start} às {this.state.accredited.access.end}</span> <br />
                            <span>{this.state.accredited.access.monday === true && "Segunda, "} </span>
                            <span>{this.state.accredited.access.tuesday === true && "Terça, "} </span>
                            <span>{this.state.accredited.access.wednesday === true && "Quarta, "} </span>
                            <span>{this.state.accredited.access.thursday === true && "Quinta, "} </span>
                            <span>{this.state.accredited.access.friday === true && "Sexta, "} </span>
                            <span>{this.state.accredited.access.saturday === true && "Sabádo, "} </span>
                            <span>{this.state.accredited.access.sunday === true && "Domingo, "} </span>
                        </div>
                    </>
                }
                {
                    this.state.vehicles.length > 0 &&
                    <div className="col-lg-12 mt-3">
                        <div className="col-lg-12 mt-3">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Placa</th>
                                        <th scope="col">Modelo</th>
                                        <th scope="col">Cor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.vehicles.map((value, index) => {
                                            return <tr key={`vehicles_${index}`}>
                                                <th>{index + 1}</th>
                                                <td>{value.plate}</td>
                                                <td>{value.model}</td>
                                                <td>{value.color}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>)
    }
}

export { AccreditedView }