export const fetchFunction = async (path, options) => {
    const url = (process.env.REACT_APP_FUNCTION_REQUEST + path)
    options.headers = {
        "Content-Type": "application/json",
        "X_API_KEY": options.apiKey
    }
    return new Promise(function (resolve, reject) {
        fetch(url, options)
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                console.log(error)
                resolve({
                    success: false,
                    message: "Erro desconhecido, tente novamente mais tarde."
                })
            })
    })
}