
import { fetchFunction } from './fetchFunction';

export const getPaymentsMethods = async (apiKey) => {
    const options = {
        method: 'GET',
        apiKey: apiKey
    };
    return await fetchFunction("/data/payments-methods", options)
}
