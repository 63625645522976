import { fetchFunction } from "../fetchFunction";

export const getParkById = async ({ parkId }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/park/${parkId}`, options)
}

export const getCurrentSubscriptionId = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/park/subscription/${id}`, options)
}

export const getAllSubscriptionByParkId = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/park/subscription/all/${id}`, options)
}