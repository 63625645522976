
import React from 'react'
import { isNullOrEmpty, queryString, toCurrency } from '../../shared/utils'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { ParkCardView } from '../cards'
import { getURLFromURI } from '../../config/api/filesWorker'
import { getSingleNoteById } from '../../config/api/form/formNote'

class SingleNoteView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            id: queryString("id"),
            park: null
        }
    }

    componentDidMount = async () => {
        const result = await getSingleNoteById({ id: this.state.id })
        this.setState({ state: "loading" })
        if (result.success) {
            this.setState({ ...result.data })
            await this.applyTheme({ park: result.data.park })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    <div className="container-md top-20">
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-center text-size-20'>NOTA FISCAL</div>
                                    <ParkCardView park={this.state.park} />
                                    {
                                        !isNullOrEmpty(this.state.note.name) &&
                                        <>
                                            <div>Nome:</div>
                                            <div className='text-bold'>{this.state.note.name}</div>
                                        </>
                                    }
                                    {
                                        !isNullOrEmpty(this.state.note.document) &&
                                        <>
                                            <div>CPF ou CNPJ:</div>
                                            <div className='text-bold'>{this.state.note.document}</div>
                                        </>
                                    }
                                    {
                                        !isNullOrEmpty(this.state.note.email) &&
                                        <>
                                            <div>E-mail:</div>
                                            <div className='text-bold'>{this.state.note.email}</div>
                                        </>
                                    }
                                    {
                                        !isNullOrEmpty(this.state.note.noteType) &&
                                        <>
                                            <div>Tipo:</div>
                                            <div className='text-bold'>{this.state.note.noteType}</div>
                                        </>
                                    }
                                    {
                                        !isNullOrEmpty(this.state.note.price) &&
                                        <>
                                            <div className='mt-4 text-center'>
                                                <div className='text-bold text-center text-size-20'>VALOR</div>
                                                <h1 className='text-bold text-success display-1'>
                                                    {toCurrency(this.state.note.price)}
                                                </h1>
                                            </div>
                                        </>
                                    }
                                    {
                                        !isNullOrEmpty(this.state.note.currentStatus) &&
                                        <div className="col-lg-12 text-center">
                                            {
                                                this.state.note.currentStatus === "NOTE_PROCESSING" &&
                                                <div className="alert alert-warning">
                                                    <h6>Estamos gerando a sua nota fiscal, isso pode levar até 2 dias.</h6>
                                                </div>
                                            }
                                            {
                                                this.state.note.currentStatus === "NOTE_ERROR" &&
                                                <div className="alert alert-danger">
                                                    <h6>Não foi possível gerar a sua nota fiscal, entre em contato com o estabelecimento.</h6>
                                                </div>
                                            }
                                            {
                                                this.state.note.currentStatus === "NOTE_CANCELED" &&
                                                <a href={this.state.note.pdf} target="_blank" rel="noreferrer" className="btn btn-danger btn-lg w-100 text-white">
                                                    <i className="fa-solid fa-file-pdf mx-2" />
                                                    Visualizar nota cancelada
                                                </a>
                                            }
                                            {
                                                this.state.note.currentStatus === "NOTE_GENERATED" &&
                                                <a href={this.state.note.pdf} target="_blank" rel="noreferrer" className="btn btn-success btn-lg w-100 text-white">
                                                    <i className="fa-solid fa-file-pdf mx-2" />
                                                    Visualizar nota
                                                </a>
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { SingleNoteView }