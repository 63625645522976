import { queryString } from "../../shared/utils"

export const getCurrentPark = () => {
    const park = localStorage.getItem('currentPark')
    if (park) {
        return JSON.parse(park)
    }
    return park
}

export const saveCurrentPark = async ({ park }) => {
    localStorage.setItem('currentPark', JSON.stringify(park))
}

export const saveClientId = async ({ id }) => {
    localStorage.setItem('currentClientId', id)
}

export const getClientIrOrQueryString = async () => {
    const id = queryString("id")
    if (!id) {
        return localStorage.getItem('currentClientId')
    }
    return id
}
