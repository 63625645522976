import { fetchFunction } from "../fetchFunction";

export const generateChargeBySubscriptionId = async ({ id, type }) => {
    const options = {
        method: 'POST'
    }
    return await fetchFunction(`/newSubscription/park/generate-charge/${type}/${id}`, options)
}

export const verifyStatusBySubscriptionId = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/park/subscription/status/${id}`, options)
}