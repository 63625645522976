import React from 'react'
import { goToWindow, loadClientSession } from '../../shared/utils.js'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container.js'
import { LoadingContainer } from '../../shared/layout/system-container.js'

class WelcomeView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading"
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const data = await loadClientSession()
        if (data) {
            this.setState({ data: data })
            this.setState({ state: "fill" })
        } else {
            this.setState({ state: "not-found" })
        }
    }

    render() {
        return (
            <div className="container-md top-20">
                {
                    this.state.data?.tema?.logo &&
                    <ImageContainer url={this.state.data?.tema?.logo} />
                }
                <RegisterContainer>
                    {this.state.state === "not-found" &&
                        <div className="alert alert-danger text-center mt-4">
                            <span className="badge text-bg-danger">Oops!</span> <p />
                            <h4>Estabelecimento não encontrado</h4>
                            <span>Entre em contato com o estabelecimento e solicite a URL correta do formulário.</span>
                        </div>
                    }
                    {this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <div className='top-10 text-center'>
                            <div className="mt-3">
                                {/* <button className='btn-card top-10'>
                                    Pagar meu ticket
                                </button> */}
                                <button onClick={e => goToWindow(`/formulario/cadastrar/?id=${this.state.data.id}`)} className='btn-card top-10'>
                                    Quero ser um mensalista
                                </button>
                                <button onClick={e => goToWindow(`/formulario/entrar/?id=${this.state.data.id}`)} className='btn-card top-10'>
                                    Minhas cobranças
                                </button>
                            </div>
                        </div>
                    }
                </RegisterContainer>
            </div>
        )
    }
}

export default WelcomeView;