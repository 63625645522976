
import React from 'react'
import { queryString, setPathWindow } from '../../../shared/utils'
import { LoadingContainer, MainContainer } from '../../../shared/layout/system-container'
import { getTicketByNumberAndParkId } from '../../../config/api/form/formTicket'
import { ImageContainer, RegisterContainer } from '../../../shared/layout/ticket-container'
import { getURLFromURI } from '../../../config/api/filesWorker'
import { TicketCardDetail, TicketExternalReview, TicketImageCardDetail, TicketInternalNotReview, TicketInternalReview, TicketPayment, TicketPermanence, TicketProductsAndServices, TicketQRCode, TicketSettingsButtons, TicketStatusSend } from '../../ticket_old/components/ticket-components'
import { NoteCardView, ParkCardView } from '../../cards'

class TicketDetailView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            park: null
        }
    }

    componentDidMount = async () => {
        const number = queryString("t")
        const parkId = queryString("e")
        const result = await getTicketByNumberAndParkId({ ticket: number, parkId: parkId })
        if (result.success) {
            setPathWindow(`/ticket/visualizar/?t=${number}&e=${parkId}&id=${result.data.ticket.id}`)
            this.setState({ ...result.data })
            await this.applyTheme({ park: result.data.park })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-center text-size-20'>DETALHES DO TICKET</div>
                                    <ParkCardView park={this.state.park} />
                                    {
                                        this.state.ticket && <>
                                            <TicketImageCardDetail ticket={this.state.ticket} />
                                            <TicketCardDetail ticket={this.state.ticket} />
                                            <NoteCardView note={this.state.ticket.note} />
                                            <TicketInternalReview ticket={this.state.ticket} />
                                            <TicketProductsAndServices ticket={this.state.ticket} />
                                            <TicketPermanence ticket={this.state.ticket} />
                                            <TicketStatusSend ticket={this.state.ticket} />
                                            <TicketExternalReview ticket={this.state.ticket} />
                                            <TicketPayment ticket={this.state.ticket} />
                                            <TicketQRCode ticket={this.state.ticket} />
                                            <TicketSettingsButtons flags={this.state.park.flags} ticket={this.state.ticket} />
                                            <TicketInternalNotReview ticket={this.state.ticket} />
                                        </>
                                    }
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { TicketDetailView }