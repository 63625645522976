
import React from 'react'
import { goToWindow, queryString } from '../../../shared/utils'
import { LoadingContainer, MainContainer } from '../../../shared/layout/system-container'
import { getTicketByNumberAndParkId } from '../../../config/api/form/formTicket'
import { ImageContainer, RegisterContainer } from '../../../shared/layout/ticket-container'
import { getURLFromURI } from '../../../config/api/filesWorker'
import { ParkCardView } from '../../cards'
import { getParkById } from '../../../config/api/form/formPark'

class TicketSearchView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            park: null,
            identifier: ""
        }
    }

    componentDidMount = async () => {
        const parkId = queryString("e")
        this.setState({ state: "loading" })
        const result = await getParkById({ parkId: parkId })
        if (result.success) {
            this.setState({ park: result.data })
            await this.applyTheme({ park: result.data })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    doSearch = async () => {
        const { identifier } = this.state
        if (identifier.length < 6) {
            this.setState({ errorMessage: "Informe o número do ticket acima de 6 dígitos ou a placa do veículo estacionado." })
            return
        }
        this.setState({ state: "loading" })
        const parkId = queryString("e")
        const result = await getTicketByNumberAndParkId({ ticket: identifier, parkId: parkId })
        if (result.success) {
            goToWindow(`/ticket/visualizar/?t=${result.data.ticket.ticket}&e=${result.data.park.id}&id=${result.data.ticket.id}`)
        } else {
            this.setState({ state: "fill" })
            this.setState({ errorMessage: result.message })
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-size-20 text-center'>BUSCAR TICKET</div>
                                    <ParkCardView park={this.state.park} />
                                    <span className="text-center">Informe o número do ticket ou a placa do veículo estacionado.</span>
                                    {
                                        this.state.errorMessage &&
                                        <div className="col-g-12 mt-2 text-center">
                                            <div className="alert alert-warning">
                                                <span>{this.state.errorMessage}</span>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <div className="col-lg-12">
                                            <input type='text' className='form-control form-control-lg mt-2 text-center' value={this.state.identifier} onChange={(e) => this.setState({ identifier: e.target.value.toUpperCase() })} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 mt-3">
                                        <button onClick={(e) => { this.doSearch() }} className="btn btn-success btn-lg w-100">
                                            <i className="fas fa-search mx-2" />
                                            Buscar veículo
                                        </button>
                                    </div>
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { TicketSearchView }