import React from 'react'
import '../ticket.css'
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import '../ticket.css'
import { sendClickButton } from '../../../shared/analytics';
import { getInitializeTicket, getRequestVehicleByTicket, getRequestVehicleEnabledByTicket, getTicketProcessing, getTicketTotal, postTicketCreditCard, postTicketPix } from '../../../config/api/ticketServicer';
import { isNullOrEmpty, minutesToDescription, searchCEP, takeIfIsNotNullOrEmpty, toastError, toastWarning, toCurrency } from '../../../shared/utils';
import { getPaymentsMethods } from '../../../config/api/paymentsMethods';
import { getResaleLogoPrinter, getResaleName, getResaleSite } from '../../../config/api/resaleService';
import { QRCodeSVG } from 'qrcode.react';
import { ContactInfoCardView } from '../../cards'
import { LoadingContainer } from '../../../shared/layout/system-container';

class PromotionImage extends React.Component {

    _clicouNoBanner = () => {
        const analytics = getAnalytics();
        if (this.props.ticket !== undefined) {
            var params = { ticket_id: this.props.ticket.id, number: this.props.ticket.ticket, estacionamento: this.props.ticket.estacionamento.nome, estacionamentoId: this.props.ticket.estacionamento.id }
            logEvent(analytics, 'abriu_banner_promocao', params);
            sendClickButton(this.props.ticket, "Abriu banner de promoção - Indique e ganhe")
        } else {
            sendClickButton(this.props.ticket, "Abriu banner de promoção - Indique e ganhe")
            logEvent(analytics, 'abriu_banner_promocao', null);
        }
    }

    render() {
        return (
            <div className='top-20' />
            // <div className="promotion-banner">
            //     <div className='top-20 text-center'>
            //         <a href='https://selfparking.page.link/indique' onClick={() => this._clicouNoBanner()} target='_bank'>
            //             <img className='img-block img-radius-10' src='assets/images/promocao_indicacao.png' alt="Promoção indicação" />
            //         </a>
            //     </div>
            // </div>
        )
    }
}

class TicketHeaderImage extends React.Component {

    render() {
        return (
            <div className='top-20 text-center'>
                <img className='img-block height-100 width-100' src={getResaleLogoPrinter()} alt={getResaleName()} />
            </div>
        )
    }
}

class TicketPromotionalHeader extends React.Component {

    render() {
        return (
            <>
                {
                    (this.props.ticket.promotialMessage && this.props.ticket.promotialMessage !== '') && <>
                        <div className='top-20 ticket-monthly text-bold text-center'>
                            {this.props.ticket.promotialMessage}
                        </div>
                    </>
                }
            </>
        )
    }
}
class TicketHeader extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.showImage && <>
                        <TicketHeaderImage />
                    </>
                }
                {
                    (this.props.estacionamento !== null && this.props.estacionamento?.nome !== null) &&
                    <div className='top-20 text-center'>
                        <div className='text-bold text-size-20'>{this.props.estacionamento?.nome}</div>
                        <div>{this.props.estacionamento?.documento}</div>
                        <div>{this.props.estacionamento?.endereco}</div>
                        <div>{this.props.estacionamento?.celular}</div>
                        <div>{this.props.estacionamento?.horarios}</div>
                    </div>
                }
            </>
        )
    }
}

class TicketFooter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            site: getResaleSite(),
            name: getResaleName()
        }
    }

    _visualizouSite = () => {
        const analytics = getAnalytics();
        if (this.props.ticket !== undefined) {
            var params = { ticket_id: this.props.ticket.id, number: this.props.ticket.ticket, estacionamento: this.props.ticket.estacionamento.nome, estacionamentoId: this.props.ticket.estacionamento.id }
            logEvent(analytics, 'click_site', params);
            sendClickButton(this.props.ticket, `Abriu site de venda do ${this.state.name}`)
        } else if (this.props.receipt !== undefined) {
            var params2 = { estacionamento: this.props.receipt.estacionamento.nome, estacionamentoId: this.props.receipt.estacionamento.id }
            logEvent(analytics, 'click_site', params2);
            sendClickButton(this.props.ticket, `Abriu site de venda do ${this.state.name}`)
        } else {
            logEvent(analytics, 'click_site_sem_ticket', null);
        }
    }

    render() {
        return (
            <>
                <div className='top-20 text-center'>
                    <div className='text-bold'><a href={this.state.site} onClick={() => this._visualizouSite()} target='_bank'>{this.state.site?.replaceAll("https://", "")}</a></div>
                    <div className='top-20'>
                        {
                            this.props.showSecurityMessage === true &&
                            <small>Por motivos de segurança, o ticket ficará disponível por apenas 24 horas após o pagamento.</small>
                        }
                    </div>
                </div>
            </>
        )
    }
}

class TicketImageCardDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            urlVehicle: null
        }
    }

    componentDidMount = () => {
        this.loadImage()
    }

    loadImage = async () => {
        if (this.props.ticket.urlVehicle != null) {
            const storage = getStorage();
            const url = await getDownloadURL(ref(storage, this.props.ticket.urlVehicle))
            this.setState({ urlVehicle: url })
        }
    }

    render() {
        return (
            <>
                {
                    this.props.ticket.urlVehicle && <>
                        <div>
                            <img className='img-block img-radius-5' src={this.state.urlVehicle} alt="Foto do veículo" />
                        </div>
                    </>
                }
            </>
        )
    }
}

class TicketCardDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showData: props.ticket.internalType !== "SALES",
        }
    }

    render() {
        return (
            <>
                {
                    this.state.showData &&
                    <div>
                        {this.props.ticket.plate && <div> <span className='text-bold'>Placa: </span>{this.props.ticket.plate}</div>}
                        {this.props.ticket.sector && <div> <span className='text-bold'>Setor: </span>{this.props.ticket.sector.name}</div>}
                        {this.props.ticket.prism && <div> <span className='text-bold'>Prisma: </span>{this.props.ticket?.prism}</div>}
                        {this.props.ticket.vehicle?.model && <div> <span className='text-bold'>Modelo: </span>{this.props.ticket.vehicler.model}</div>}
                        {this.props.ticket.vehicle?.brand && <div> <span className='text-bold'>Marca: </span>{this.props.ticket.vehicler.brand}</div>}
                        {this.props.ticket.vehicle?.color && <div> <span className='text-bold'>Cor: </span>{this.props.ticket.vehicler.color}</div>}
                        <div> <span className='text-bold'>Status:</span> {this.props.ticket.status}</div>
                    </div>
                }
            </>
        )
    }
}

class TicketCardTotals extends React.Component {
    state = {
        data: null,
        addressMessage: null,
        state: "loading-calculate", //loading, loading-calculate, type-payment, documents, pix, processing-payment, credit-card
        document: "",
        name: "",
        holder: "",
        addressNumber: "",
        number: "",
        expiration: "",
        cvv: ""
    }

    constructor(props) {
        super(props)
        this.loadData()
    }

    loadData = async () => {
        const ticket = this.props.ticket
        const inicialize = await getInitializeTicket(ticket)
        if (!inicialize.success) {
            return
        }
        const apiKey = inicialize.data.parameter.apiKey
        const result = await getTicketTotal(ticket, apiKey)
        const resultPayments = await getPaymentsMethods(apiKey)
        if (resultPayments.success) {
            const acceptedMethodPayments = resultPayments.data.filter(e => e.type === "Crédito" || e.type === "Pix")
            if (result.success) {
                this.setState({ apiKey: apiKey })
                this.setState({ data: result.data })
                this.setState({ methodsPayment: acceptedMethodPayments })
                this.setState({ state: "type-payment" })
            }
        } else {
            toastError(resultPayments.message)
        }
    }

    tappedPaymentMethod = (type) => {
        this.setState({ methodPaymentSelected: type })
        this.setState({ state: "documents" })
        this.setState({ message: "Para continuar com o pagamento, precisamos de algumas informações pessoais." })
    }

    findCEP = async (cep) => {
        this.setState({ cep: cep })
        this.setState({ addressMessage: null })
        const address = await searchCEP(cep)
        if (address !== null) {
            if (address.erro === true) {
                toastWarning("Informe um CEP válido.")
                this.setState({ cep: "" })
                this.setState({ addressMessage: null })
                this.setState({ address: null })
            } else {
                this.setState({ address: address })
                const addressMessage = `${address.logradouro}, ${address.bairro} - ${address.cidade}`
                this.setState({ addressMessage: addressMessage })
            }
        }
    }

    copyPixKey = async () => {
        const key = this.state.pixResult.key
        navigator.clipboard.writeText(key);
        toastWarning("Pix Copia e Cola copiado com sucesso.")
    }

    tappedContinue = async () => {
        if (!this.state.name) {
            toastWarning("Informe seu nome completo.")
            return
        }
        if (!this.state.document) {
            toastWarning("Informe um documento válido.")
            return
        }
        if (!this.state.address) {
            toastWarning("Informe um CEP válido.")
            return
        }
        if (!this.state.addressNumber) {
            toastWarning("Informe o número do endereço.")
            return
        }
        if (this.state.methodPaymentSelected.type === "Crédito") {
            this.setState({ state: "credit-card" })
        } else {
            await this.generatePixQRCode()
        }
    }

    generatePixQRCode = async () => {
        // const ip = await searchIp()
        const customer = {
            name: this.state.name,
            document: this.state.document,
            // id: ip,
            address: {
                zipCode: this.state.address.cep,
                street: this.state.address.logradouro,
                number: this.state.addressNumber,
                district: this.state.address.bairro,
                cityName: this.state.address.cidade,
                stateInitials: this.state.address.estado,
                countryName: "Brasil"
            }
        }
        this.setState({ state: "processing-payment" })
        const result = await postTicketPix(this.props.ticket, customer, this.state.apiKey)
        if (result.success) {
            this.setState({ pixResult: result.data })
            this.setState({ state: "pix" })
            this.verifyPaymentProcessing()
        } else {
            this.setState({ state: "documents" })
            toastWarning(result.message)
        }
    }

    verifyPaymentProcessing = async () => {
        const result = await getTicketProcessing(this.props.ticket, this.state.apiKey)
        if (result.success) {
            const status = result.data.status
            if (status === "APPROVED") {
                toastWarning("Pagamento registrado com sucesso.\n\nVisualizar recibo de pagamento")
                window.location.reload()
            } else {
                await new Promise(resolve => setTimeout(resolve, 5000));
                await this.verifyPaymentProcessing()
            }
        } else {
            toastWarning(result.message)
            window.location.reload()
        }
    }

    tappedPaymentWithCreditCard = async () => {
        // const ip = await searchIp()
        const customer = {
            name: this.state.name,
            document: this.state.document,
            // id: ip,
            address: {
                zipCode: this.state.address.cep,
                street: this.state.address.logradouro,
                number: this.state.addressNumber,
                district: this.state.address.bairro,
                cityName: this.state.address.cidade,
                stateInitials: this.state.address.estado,
                countryName: "Brasil"
            }
        }
        const card = {
            holder: this.state.holder,
            number: this.state.number,
            expiration: this.state.expiration,
            cvv: this.state.cvv,
        }
        this.setState({ state: "processing-payment" })
        const result = await postTicketCreditCard(this.props.ticket, customer, card, this.state.apiKey)
        if (result.success === true) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            toastWarning("Pagamento registrado com sucesso.\n\nVisualizar recibo de pagamento")
            window.location.reload()
        } else {
            this.setState({ state: "credit-card" })
            toastWarning(result.message)
        }
    }

    render() {
        return (
            <>
                {
                    this.state.state !== "loading" && this.state.data && <>
                        <div>Permanência: {minutesToDescription(this.state.data.total.permanence)}</div>
                        <div className='top-20 text-center'>
                            <strong>Valor total</strong><br />
                            <strong className='font-40 text-success'> <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.data.total.totalValue} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong>
                        </div>
                        <div className='top-10 text-center'>
                            <strong>Permanência máxima</strong><br />
                            <strong className='text-danger'>
                                {Moment(this.state.data.total.limitPermanenceInDate).format("DD/MM/yyyy [às] HH:mm")}
                            </strong>
                        </div>
                    </>
                }
                {
                    this.state.state === "loading" && <>
                        <span className="loader" />
                    </>
                }
                {
                    this.state.state === "loading-calculate" && <>
                        <span className="loader" />
                        <h4 className='text-center'>Aguarde, estamos calculando o valor do seu ticket.</h4>
                    </>
                }
                {
                    this.state.state === "type-payment" && <>
                        <div className='top-10 text-center'>
                            <strong>Pagar usando:</strong><br />
                        </div>
                        {
                            this.state.methodsPayment.map(item =>
                                <button key={item.id} className='btn-card top-10' onClick={e => { this.tappedPaymentMethod(item) }}>
                                    {item.name.toUpperCase()}
                                </button>
                            )
                        }
                    </>
                }
                {
                    this.state.state === "documents" && <>
                        <h4 className='text-center'>{this.state.message}</h4>
                        <input type='text' className='text-input-rounded top-10' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} placeholder='Nome completo' />
                        <input type='tel' className='text-input-rounded top-10' value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} placeholder='CPF ou CNPJ' />
                        <NumberFormat autoComplete='off' className="text-input-rounded top-10" value={this.state.cep} onChange={(e) => this.findCEP(e.target.value)} format="#####-###" placeholder='CEP' />
                        {
                            this.state.addressMessage && <h5 className='margin-0'>{this.state.addressMessage}</h5>
                        }
                        <input type='text' className='text-input-rounded top-10' value={this.state.addressNumber} onChange={(e) => this.setState({ addressNumber: e.target.value })} placeholder='Número' />
                        <button className='btn-card top-10' onClick={this.tappedContinue}>
                            Continuar
                        </button>
                        <button className='btn-gray top-10' onClick={(e) => this.setState({ state: "type-payment" })}>
                            Voltar
                        </button>
                    </>
                }
                {
                    this.state.state === "pix" && <>
                        <h4 className='text-center text-success'>{this.state.pixResult.message}</h4>
                        <QRCodeSVG value={this.state.pixResult.qrcodeImage} size={100} />
                        <h4 className='text-center text-qrcode'>{this.state.pixResult.key}</h4>
                        <button className='btn-red top-10' onClick={this.copyPixKey}>
                            Copiar código
                        </button>
                        <div className='text-center top-10'>
                            <strong>Como realizar o pagamento</strong><p />
                        </div>
                        <small>
                            1 - Abra a conta do seu banco e selecione Pix.<br />
                            2 - Selecione a opção Pix Copia e Cola.<br />
                            3 - Cole o código, confirme as informações e finalize o pagamento.<br />
                        </small>
                    </>
                }
                {
                    this.state.state === "credit-card" && <>
                        <h4 className='text-center text-success'>Nós estamos em um ambiente seguro e não salvamos nenhum dado de cartão de crédito.</h4>
                        <input type='text' className='text-input-rounded top-10' value={this.state.holder} onChange={(e) => this.setState({ holder: e.target.value })} placeholder='Nome igual no cartão' />
                        <NumberFormat autoComplete='off' className="text-input-rounded top-10" value={this.state.number} onChange={(e) => this.setState({ number: e.target.value })} format="####.####.####.####" placeholder='Número do cartão' />
                        <NumberFormat autoComplete='off' className="text-input-rounded top-10" value={this.state.expiration} onChange={(e) => this.setState({ expiration: e.target.value })} format="##/####" placeholder='Vencimento' />
                        <NumberFormat autoComplete='off' className="text-input-rounded top-10" value={this.state.cvv} onChange={(e) => this.setState({ cvv: e.target.value })} format="####" placeholder='Código de segurança' />
                        <button className='btn-card top-10' onClick={this.tappedPaymentWithCreditCard}>
                            Confirmar pagamento
                        </button>
                        <button className='btn-gray top-10' onClick={(e) => this.setState({ state: "documents" })}>
                            Voltar
                        </button>
                    </>
                }
                {
                    this.state.state === "processing-payment" && <>
                        <span className="loader" />
                        <h4 className='text-center'>Aguarde, estamos processando seu pedido.</h4>
                    </>
                }
            </>
        )
    }
}

class TicketStatusSend extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.ticket.statusSent?.length !== 0 &&
                    <div>
                        {
                            this.props.ticket.statusSent !== undefined && this.props.ticket.statusSent !== null &&
                            <div className='text-bold text-center text-size-20 top-20'>MENSAGENS RECEBIDAS</div>
                        }
                        {
                            this.props.ticket.statusSent?.map((item, index) =>
                                <div className='top-20' key={index}>
                                    <div>{item.message} </div>
                                    <small>Data: {Moment(item.createdAt._Seconds).format("DD/MM/yyyy [às] HH:mm")}</small> <p />
                                </div>
                            )
                        }
                    </div>
                }
            </>
        )
    }
}


class TicketMonthly extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.ticket.monthly &&
                    <div className='top-20 ticket-monthly'>
                        <div className='text-bold text-center'>Cliente mensalista</div>
                        <div className='text-center'>{this.props.ticket.monthly?.nome}</div>
                        {
                            this.props.ticket.monthlyMessage &&
                            <div className='text-center top-20'>{this.props.ticket.monthlyMessage}</div>
                        }
                    </div>
                }
            </>
        )
    }
}

class TicketInternalReview extends React.Component {

    render() {
        return (
            <>
                {
                    !isNullOrEmpty(this.props.ticket.internalReview) &&
                    <div className='top-20'>
                        <div className='text-bold text-center text-size-20 top-20'>AVALIAÇÃO INTERNA</div>
                        <div className='top-20'>
                            {
                                (this.props.ticket.internalReview ?? []).map(e => <div key={Math.random()}>{e.quantity} - {e.name}</div>)
                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}

class TicketExternalReview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showData: props.ticket.internalType !== "SALES",
            urlExternal: null
        }
    }

    componentDidMount = () => {
        this.loadImage()
    }

    loadImage = async () => {
        if (!isNullOrEmpty(this.props.ticket.urlExternal)) {
            const storage = getStorage();
            const url = await getDownloadURL(ref(storage, this.props.ticket.urlExternal))
            this.setState({ urlExternal: url })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.showData && <>
                        {takeIfIsNotNullOrEmpty(this.props.ticket.urlExternal) &&
                            <div className='top-20 text-bold'>
                                <div className='text-bold text-center text-size-20 top-20'>AVALIAÇÃO EXTERNA</div>
                                <img className='img-block img-radius-5 top-20' alt="Foto do veículo" src={this.state.urlExternal} />
                            </div>
                        }
                    </>
                }
            </>
        )
    }
}

class TicketInternalNotReview extends React.Component {

    render() {
        return (
            <>
                {
                    !isNullOrEmpty(this.props.ticket.internalReview) &&
                    <div className='text-center'>
                        Nenhum objeto de valor foi declarado dentro do veículo pelo cliente
                    </div>
                }
            </>
        )
    }
}

class TicketPayment extends React.Component {

    render() {
        return (
            <>
                {
                    !isNullOrEmpty(this.props.ticket.paymentsLogged) &&
                    <div>
                        <div className='text-bold text-center text-size-20 mt-3'>PAGAMENTOS</div>
                        {
                            this.props.ticket.paymentsLogged?.map((payment, index) =>
                                <div key={`pagamentos_${index}`} className='mt-2'>
                                    {
                                        payment.canceled &&
                                        <div className="badge text-bg-danger mb-2">Pagamento cancelado</div>
                                    }
                                    {
                                        payment.online &&
                                        <div className="badge text-bg-success mb-2">Pagamento online</div>
                                    }
                                    <div>Data: {Moment(payment.createdAt._seconds * 1000).format("DD/MM/yyyy [às] HH:mm")}</div>
                                    {
                                        !isNullOrEmpty(payment.createdBy?.name) &&
                                        <div>Operador: {payment.createdBy?.name.split(' ')[0]}</div>
                                    }
                                    <div>Valor: {toCurrency(payment.resume?.total ?? 0)}</div>
                                    {
                                        !isNullOrEmpty(payment.paymentMethod?.type) &&
                                        <div>Tipo: {payment.paymentMethod?.type} </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                }
            </>
        )
    }
}

class TicketRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            requestVehicleButton: false,
            requestCancelVehicleButton: false,
            message: "",
            state: "",
        }
    }

    componentDidMount = async () => {
        const result = await getRequestVehicleEnabledByTicket(this.props.ticket)
        if (result.success) {
            this.setState({ contactInfo: result.data.contactInfo })
            this.setState({ requestVehicleButton: result.data.isEnabled && result.data.requestButton })
            this.setState({ requestCancelVehicleButton: result.data.isEnabled && result.data.cancelButton })
            this.setState({ message: result.data.message })
            this.setState({ state: "fill" })
        }
    }

    onRequest = async () => {
        var confirm = window.confirm(`Confirma mesmo executar esta ação?`);
        if (confirm) {
            this.setState({ state: "loading" })
            const result = await getRequestVehicleByTicket(this.props.ticket)
            if (result.success) {
                this.setState({ requestVehicleButton: result.data.requestButton })
                this.setState({ requestCancelVehicleButton: result.data.cancelButton })
                this.setState({ message: result.data.message })
                this.setState({ state: "fill" })
            }
        }
    }

    render() {
        return (
            <>
                <ContactInfoCardView contactInfo={this.state.contactInfo}>
                    {
                        this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            {
                                this.state.requestVehicleButton && <>
                                    {
                                        this.state.contactInfo ?
                                            <div className="col-md-12 mt-2">
                                                <button onClick={this.onRequest} className="btn btn-success btn-lg w-100 text-white">
                                                    <i className="fas fa-car mx-2" />
                                                    Solicitar veículo
                                                </button>
                                            </div> :
                                            <div className="col-md-12 mt-2 text-center">
                                                <a href={`/contato/cadastrar/?id=${this.props.ticket.id}`} className="btn btn-success btn-lg w-100 text-white">
                                                    <i className="fas fa-car mx-2" />
                                                    Solicitar veículo
                                                </a>
                                                <div className="mt-2">
                                                    Será preciso cadastrar alguns dados básicos para poder solicitar o veículo.
                                                </div>
                                            </div>
                                    }
                                </>
                            }
                            {
                                this.state.requestCancelVehicleButton &&
                                <div className="col-md-12 mt-2">
                                    <button onClick={this.onRequest} className="btn btn-danger btn-lg w-100 text-white">
                                        <i className="fas fa-car mx-2" />
                                        Cancelar solicitação
                                    </button>
                                </div>
                            }
                            {
                                !isNullOrEmpty(this.state.message) &&
                                <div className="col-md-12 mt-2 text-center">
                                    <strong className="text-success">{this.state.message}</strong>
                                </div>
                            }
                        </>
                    }
                </ContactInfoCardView>
            </>
        )
    }
}

class TicketPermanence extends React.Component {

    constructor(props) {
        super(props)
        if (props.ticket.internalType === "SALES") {
            this.state = {
                showData: false,
            }
            return
        }
        let checkin = Moment(this.props.ticket.createdAt._seconds * 1000).toDate()
        if (!this.props.ticket.closedAt) {
            this.state = {
                showData: true,
                entrada: {
                    data: Moment(checkin).format("DD/MM/yyyy"),
                    hora: Moment(checkin).format("HH:mm"),
                }, saida: {
                    data: null,
                    hora: null,
                    permanencia: {
                        horas: null,
                        minutos: null,
                        description: null
                    }
                }
            }
        } else {
            let checkOut = Moment(this.props.ticket.closedAt._seconds * 1000).toDate() ?? new Date()
            var duration = Moment.duration(Moment(checkOut).diff(Moment(checkin)));
            this.state = {
                showData: true,
                entrada: {
                    data: Moment(checkin).format("DD/MM/yyyy"),
                    hora: Moment(checkin).format("HH:mm"),
                },
                saida: {
                    data: checkOut != null ? Moment(checkOut).format("DD/MM/yyyy") : null,
                    hora: checkOut != null ? Moment(checkOut).format("HH:mm") : null,
                    permanencia: {
                        horas: parseInt(duration.asHours()),
                        minutos: parseInt(duration.asMinutes()),
                        description: this.desciptionPermanence()
                    }
                }
            }
        }
    }

    desciptionPermanence = () => {
        let checkin = Moment(this.props.ticket.createdAt._seconds * 1000).toDate()
        let checkOut = Moment(this.props.ticket.closedAt._seconds * 1000).toDate() ?? new Date()
        var duration = Moment.duration(Moment(checkOut).diff(Moment(checkin)))
        var hours = parseInt(duration.asHours())
        var minutes = parseInt(duration.asMinutes()) - (hours * 60)
        if (hours === 0) {
            if (minutes === 0) {
                return "Menos de um minuto"
            }
            if (minutes === 1) {
                return minutes + " minuto"
            }
            return minutes + " minutos"
        }
        if (hours === 1) {
            if (minutes === 1) {
                return hours + " hora e " + minutes + " minuto"
            }
            return hours + " hora e " + minutes + " minutos"
        }
        return hours + " horas e " + minutes + " minutos"
    }

    render() {
        return (
            <>
                {
                    this.state.showData &&
                    <div className='top-10'>
                        <div>Entrada:</div>
                        <div className='text-bold'>{this.state.entrada.data} às {this.state.entrada.hora}</div>
                        {
                            this.state.saida.data !== null &&
                            <>
                                <div>Saída: </div>
                                <div className='text-bold'>{this.state.saida.data} às {this.state.saida.hora}</div>
                                <div>
                                    Permanência:
                                </div>
                                <div className='text-bold'>{this.state.saida.permanencia.description}</div>
                            </>
                        }
                    </div>
                }
            </>
        )
    }
}

class TicketQRCode extends React.Component {

    urlTicketOnlineFromTicket = () => {
        return window.location
    }

    render() {
        return (
            <>
                <div className='top-20 text-center'>
                    <QRCodeSVG value={this.urlTicketOnlineFromTicket()} size={200} />
                    <div className='ticket-number'>
                        {this.props.ticket.ticket}
                    </div>
                </div>
            </>
        )
    }
}

class TicketProductsAndServices extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.ticket.products?.length > 0 && <>
                        <div className='text-bold text-center text-size-20 top-20'>PRODUTOS E SERVIÇOS</div>
                        <div className='top-20'>
                            {
                                (this.props.ticket.products ?? []).map((e, index) =>
                                    <div key={index}>
                                        Nome: {e.name} <br />
                                        {e.quantity} - <NumberFormat decimalScale={2} fixedDecimalScale={true} value={e.value} displayType={'text'} thousandSeparator={true} prefix={'R$'} renderText={value => <span>{value}</span>} />
                                        <p />
                                    </div>)
                            }
                        </div>
                    </>
                }
            </>
        )
    }
}

class TicketLoading extends React.Component {

    render() {
        return (
            <>
                <div className='ticket text-center'>
                    <TicketHeaderImage />
                    <h2>Aguarde...</h2>
                    <small>Estamos buscando as informações do seu ticket.</small>
                </div>
            </>
        )
    }
}

class TicketNotFound extends React.Component {

    render() {
        return (
            <>
                <PromotionImage />
                <div className='ticket text-center'>
                    <TicketHeaderImage />
                    <h2>O seu ticket não foi encontrado!</h2>
                    <TicketFooter showSecurityMessage={true} />
                </div>
            </>
        )
    }
}

class TicketSettingsButtons extends React.Component {

    render() {
        return (
            <>
                {
                    this.props.flags.isRequestEnabled &&
                    <TicketRequest ticket={this.props.ticket} />
                }
                {
                    this.props.flags.isPaymentEnabled &&
                    <div className="col-md-12 col-lg-12">
                        <a href={`/ticket/pagar/?t=${this.props.ticket.ticket}&e=${this.props.ticket.parkId}`} className="btn btn-success btn-lg w-100 text-white">
                            <i className="fas fa-money-bill-wave-alt mx-2" />
                            Efetuar pagamento
                        </a>
                    </div>
                }
                {/* {
                    this.props.flags.isNoteEnabled && !this.props.ticket.note && this.props.ticket.status === "Pago e Entregue" &&
                    <div className="col-md-12 col-lg-12">
                        <a href={`/ticket/pagar/?t=${this.props.ticket.ticket}&e=${this.props.ticket.parkId}`} className="btn btn-warning btn-lg w-100 text-white">
                            <i className="fas fa-file-pdf mx-2" />
                            Emitir nota fiscal
                        </a>
                    </div>
                } */}
            </>
        )
    }
}

class TicketResumeTotal extends React.Component {

    render() {
        return (
            <div className='mt-2'>
                <div> <span className='text-bold'>Permanência: </span><br />{minutesToDescription(this.props.total.permanence)}</div>
                {
                    this.props.total.limitPermanenceInDate &&
                    <div> <span className='text-bold'>Limite de permanência: </span><br />{Moment(this.props.total.limitPermanenceInDate).format("DD/MM/YYYY [às] HH:mm")}</div>
                }
                <div className='mt-2'>
                    {this.props.total.productsValue > 0 && <div> <span className='text-bold'>Produtos: </span>{toCurrency(this.props.total.productsValue)}</div>}
                    {this.props.total.servicesValue > 0 && <div> <span className='text-bold'>Serviços: </span>{toCurrency(this.props.total.servicesValue)}</div>}
                    {this.props.total.parkingValue && <div> <span className='text-bold'>Estacionamento: </span>{toCurrency(this.props.total.parkingValue)}</div>}
                    {this.props.total.discountValue > 0 && <div> <span className='text-bold'>Desconto: </span>-{toCurrency(this.props.total.discountValue)}</div>}
                </div>
                <div className='mt-4 text-center'>
                    <div className='text-bold text-center text-size-20 top-20'>TOTAL</div>
                    <h1 className='text-bold text-success display-1'>
                        {toCurrency(this.props.total.totalValue)}
                    </h1>
                </div>
            </div>
        )
    }
}

export {
    PromotionImage,
    TicketHeaderImage,
    TicketHeader,
    TicketPromotionalHeader,
    TicketPayment,
    TicketLoading,
    TicketNotFound,
    TicketRequest,
    TicketQRCode,
    TicketFooter,
    TicketCardDetail,
    TicketImageCardDetail,
    TicketInternalReview,
    TicketExternalReview,
    TicketInternalNotReview,
    TicketProductsAndServices,
    TicketPermanence,
    TicketMonthly,
    TicketStatusSend,
    TicketCardTotals,
    TicketSettingsButtons,
    TicketResumeTotal
}