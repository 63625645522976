
import React from 'react'
import { applyThemeBySettings, copyClipboard, goToNewWindow, goToWindow, queryString, reloadWithAlert, toastError, toCurrency } from '../../shared/utils'
import { getAllSubscriptionByParkId, getCurrentSubscriptionId } from '../../config/api/form/formPark'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { getURLFromURI } from '../../config/api/filesWorker'
import { NewNoteButton } from '../ticket_old/receipt/receipt'
import moment from 'moment'
import { generateChargeBySubscriptionId, verifyStatusBySubscriptionId } from '../../config/api/form/formParkSubscription'
import { QRCodeSVG } from 'qrcode.react';

class SubscriptionDetailView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            data: null,
            buttonState: "",
            identifier: ""
        }
    }

    componentDidMount = async () => {
        const id = queryString("id")
        this.setState({ state: "loading" })
        const result = await getCurrentSubscriptionId({ id: id })
        if (result.success) {
            const data = result.data
            if (data.subscription.status === "PAYED") {
                this.setState({ buttonState: "payed" })
            } else {
                this.setState({ buttonState: "buttons" })
            }
            this.setState({ ...data })
            const url = await getURLFromURI(result.data.settings.logo)
            this.setState({ url: url })
            await applyThemeBySettings({ settings: result.data.settings })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    payByPaymentMethod = async (method) => {
        this.setState({ buttonState: "loading" })
        const result = await generateChargeBySubscriptionId({ id: this.state.subscription.id, type: method })
        if (result.success) {
            this.setState({ buttonState: `fill-${method}` })
            this.setState({ paymentData: result.data })
            if (method === "boleto") {
                if (result.data.url) {
                    goToNewWindow(result.data.url)
                }
            } else if (method === "pix") {
                this.verifyPixPayment()
            }
        } else {
            toastError(result.message)
            this.setState({ buttonState: "buttons" })
        }
    }

    verifyPixPayment = async () => {
        await new Promise(resolve => setTimeout(resolve, 5000))
        const result = await verifyStatusBySubscriptionId({ id: this.state.subscription.id })
        if (result.success) {
            if (result.data.status === "PAYED") {
                reloadWithAlert("Pagamento registrado com sucesso.")
            } else {
                this.verifyPixPayment()
            }
        } else {
            reloadWithAlert("Não foi possível verificar o pagamento.\nTente novamente.")
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className="col-lg-12">
                                        <h5 className='text-center'>DETALHES DA COBRANÇA</h5>
                                        {
                                            this.state.subscription.status === "PENDING" &&
                                            <div className="alert alert-warning">
                                                <span className="badge text-bg-warning text-white">Pendente</span><br />
                                                <h5 className="mt-2"><strong>{this.state.client.name}</strong></h5>
                                            </div>
                                        }
                                        {
                                            this.state.subscription.status === "PAYED" &&
                                            <div className="alert alert-success">
                                                <span className="badge text-bg-success text-white">Paga</span><br />
                                                <h5 className="mt-2"><strong>{this.state.client.name}</strong></h5>
                                            </div>
                                        }
                                        <strong>Valor da assinatura</strong>
                                        <h4>{toCurrency(this.state.subscription.netValue)}</h4>
                                        <strong>Vencimento</strong><br />
                                        <h4>{moment(this.state.subscription.changeDate._seconds * 1000).format("DD/MM/YYYY")}</h4>
                                        <strong>Frequência</strong><br />
                                        <h4>{this.state.subscription.frequence}</h4>
                                        <div className="mt-2">
                                            <strong>Estabelecimentos</strong><br />
                                            {
                                                this.state.subscription?.parks?.map(e => {
                                                    return <><span>{e.nome}</span><br /></>
                                                })
                                            }
                                        </div>
                                        <NewNoteButton newNote={this.state.subscription.newNote} />
                                        {
                                            this.state.buttonState === "loading" &&
                                            <div className="text-center">
                                                <LoadingContainer />
                                            </div>
                                        }
                                        <div className="mt2">
                                            {
                                                this.state.buttonState === "buttons" &&
                                                <div className="mt-2">
                                                    <h5 className='text-center'>ESCOLHA A FORMA DE PAGAMENTO</h5>
                                                    {
                                                        this.state.paymentMethod.pix === true &&
                                                        <button onClick={(e) => this.payByPaymentMethod("pix")} className="btn btn-success w-100 btn-lg mt-2 text-white">
                                                            <i className="fa-brands fa-pix mx-2" />
                                                            Pagar em Pix
                                                        </button>
                                                    }
                                                    {
                                                        this.state.paymentMethod.boleto === true &&
                                                        <button onClick={(e) => this.payByPaymentMethod("boleto")} className="btn btn-success w-100 btn-lg mt-2 text-white">
                                                            <i className="fas fa-file-invoice mx-2" />
                                                            Pagar em boleto bancário
                                                        </button>
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.buttonState === "fill-boleto" &&
                                                <>
                                                    <a href={this.state.paymentData?.url} target="_blank" rel="noreferrer" className="btn btn-success w-100 btn-lg text-white mt-3">
                                                        <i className="fas fa-file-invoice mx-2" />
                                                        Visualizar boleto
                                                    </a>
                                                </>
                                            }
                                            {
                                                this.state.buttonState === "fill-pix" &&
                                                <div className="row mt-3 text-center">
                                                    <h4><strong>Pagamento via Chave Pix</strong></h4>
                                                    <div className="col-12 mt-2">
                                                        {
                                                            this.state.paymentData?.key && <>
                                                                <QRCodeSVG value={this.state.paymentData?.key} size={200} />
                                                                <textarea disabled className="form-control text-center mt-2" rows="5">
                                                                    {this.state.paymentData?.key}
                                                                </textarea>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="mt-3">
                                                        <button onClick={(e) => copyClipboard(this.state.paymentData?.key)} className="btn btn-secondary w-100 text-white">
                                                            <i className="fa-solid fa-copy mx-2" />
                                                            Copiar chave
                                                        </button>
                                                    </div>
                                                    <span className="text-center mt-2">Copie a chave Pix acima e faça o pagamento em até 30 minutos no seu banco de preferência.</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

class SubscriptionView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            data: null,
            identifier: ""
        }
    }

    componentDidMount = async () => {
        const id = queryString("id")
        this.setState({ state: "loading" })
        const result = await getAllSubscriptionByParkId({ id: id })
        if (result.success) {
            this.setState({ client: result.data.client })
            this.setState({ subscriptions: result.data.subscriptions })
            const url = await getURLFromURI(result.data.settings.logo)
            this.setState({ url: url })
            await applyThemeBySettings({ settings: result.data.settings })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    showSubscription = async (item) => {
        const url = `/assinatura/estabelecimento/detalhes/?id=${item.id}`
        goToWindow(url)
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <h5>COBRANÇAS</h5>
                                            <span>Cobrançås do estabelecimento {this.state.client.name}</span>
                                        </div>
                                        <div className='mt-3'>
                                            {
                                                this.state.subscriptions?.map(item => {
                                                    return <div className={`alert alert-${item.status === "PAYED" ? "success" : "secondary"}`}>
                                                        <span className={`badge text-bg-${item.status === "PAYED" ? "success" : "secondary"}`}>
                                                            {item.plan.nome}
                                                        </span><br />
                                                        <strong>Valor</strong>
                                                        <h4>{toCurrency(item.netValue)}</h4>
                                                        <strong>Vencimento</strong><br />
                                                        <h6>{moment(item.changeDate._seconds * 1000).format("DD/MM/YYYY")}</h6>
                                                        <a href={`/assinatura/estabelecimento/detalhes/?id=${item.id}`} className={`btn btn-${item.status === "PAYED" ? "success" : "secondary"} w-100 btn-lg text-white`}>
                                                            <i className="fas fa-money-bill mx-2" />
                                                            Detalhes
                                                        </a>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer >
            </div >
        )
    }
}

export { SubscriptionDetailView, SubscriptionView }