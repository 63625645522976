import { fetchFunction } from "../fetchFunction"

export const initializeForm = async ({ id }) => {
    const options = {
        method: 'GET',
    }
    return await fetchFunction(`/form/register/inicialize/${id}`, options)
}

export const loginForm = async ({ data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/form/register/login`, options)
}