import { fetchFunction } from "../fetchFunction";

export const postFormAccredited = async ({ data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/form/register/accredited/save`, options)
}

export const getFormAccreditedById = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/accredited/${id}`, options)
}
