import React from 'react'
import { TicketLoading, TicketNotFound } from './components/ticket-components'
import './ticket.css'
import { MainContainer } from '../../shared/layout/system-container'
import { goToWindow } from '../../shared/utils'

//Sera removido em um outro momento
class TicketView extends React.Component {

    constructor(props) {
        super(props)
        const query = new URLSearchParams(window.location.search)
        this.state = {
            number: query.get('t'),
            parkId: query.get('e'),
            type: query.get('ty'),
            origin: query.get('origin'),
            state: 'loading',
        }
    }

    componentDidMount = async () => {
        if (this.state.number === null) {
            this.setState({ state: 'notFound' })
            return
        }
        if (this.state.type === 'new-note') {
            goToWindow(`/nota/visualizar/?t=${this.state.number}&e=${this.state.parkId}`)
        } else {
            goToWindow(`/ticket/visualizar/?t=${this.state.number}&e=${this.state.parkId}`)
        }
    }

    render() {
        return (
            <MainContainer>
                {
                    this.state.state === 'notFound' &&
                    <TicketNotFound />
                }
                {
                    this.state.state === 'loading' &&
                    <TicketLoading />
                }
            </MainContainer>
        )
    }
}

export default TicketView;