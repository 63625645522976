import { fetchFunction } from "../fetchFunction";

export const getTicketByNumberAndParkId = async ({ ticket, parkId }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/ticket/${ticket}/park/${parkId}`, options)
}

export const generatePixByTicket = async ({ data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/gateway/payment/ticket/pix`, options)
}

export const validatePixByTicketId = async ({ id }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/gateway/payment/ticket/${id}/pix`, options)
}

export const deletePixByTicketId = async ({ id }) => {
    const options = {
        method: 'DELETE'
    }
    return await fetchFunction(`/gateway/payment/ticket/${id}/pix`, options)
}
