
import React from 'react'
import { isNullOrEmpty, isValidDocument, reloadWithAlert, takeIfIsNotNullOrEmpty, toastSuccess, toastWarning } from '../../shared/utils'
import NumberFormat from 'react-number-format'
import { LoadingContainer } from '../../shared/layout/system-container'
import { deletePixById, generatePixById, validateCreditCardById, validatePixById } from '../../config/api/form/formMonthlyCharge'
import { MainContainer } from '../../shared/layout/system-container'

class PaymentFlowView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: props.id,
            type: props.type,
            status: props.status,
            state: "fill",
            pix: props.pix,
            credit: props.credit,
            note: props.note,
            noteSettings: props.park.settings.note,
            noteDocument: props.document,
            emitirNotaFiscal: props.park.settings.note.allPayments,
            cardName: "",
            cardNumber: "",
            cardDocument: "",
            cardCVV: "",
            cardDueDate: "",
        }
    }

    selectPixPaymentMethod = async () => {
        if (!this.validateNoteFields()) {
            return
        }
        this.setState({ state: "loading-pix" })
        const data = {
            id: this.state.id,
            type: this.state.type,
            note: {
                enabled: this.state.emitirNotaFiscal,
                document: this.state.noteDocument
            }
        }
        const result = await generatePixById({ id: this.state.id, data: data, type: this.state.type })
        if (result.success) {
            this.setState({ pixQRCode: result.data })
            this.setState({ state: "data-pix" })
            this.verifyPixPayment()
        } else {
            reloadWithAlert(result.message)
        }
    }

    verifyPixPayment = async () => {
        await new Promise(resolve => setTimeout(resolve, 5000))
        const result = await validatePixById({ id: this.state.id, type: this.state.type })
        if (result.success) {
            if (result.data.status === "payed") {
                reloadWithAlert("Pagamento registrado com sucesso.")
            } else {
                this.verifyPixPayment()
            }
        } else {
            reloadWithAlert("Não foi possível verificar o pagamento.\nTente novamente.")
        }
    }

    pixCopyPaste = (pixCopyPaste) => {
        navigator.clipboard.writeText(pixCopyPaste)
        toastSuccess("Pix Copia e Cola copiado com sucesso.")
    }

    selectCreditPaymentMethod = async () => {
        this.setState({ state: "data-credit" })
    }

    confirmCreditPaymentMethod = async () => {
        if (!this.validateNoteFields()) {
            return
        }
        if (isNullOrEmpty(this.state.cardName)) {
            toastWarning("Informe o nome que esta no cartão de crédito.")
            return
        }
        if (isNullOrEmpty(this.state.cardDocument)) {
            toastWarning("Informe o documento do pagador.")
            return
        }
        if (!isValidDocument(this.state.cardDocument)) {
            toastWarning("Informe um documento válido.")
            return
        }
        if (isNullOrEmpty(this.state.cardNumber)) {
            toastWarning("Informe o número que esta no cartão de crédito.")
            return
        }
        if (isNullOrEmpty(this.state.cardCVV)) {
            toastWarning("Informe o código de segurança que esta no cartão de crédito.")
            return
        }
        if (isNullOrEmpty(this.state.cardDueDate)) {
            toastWarning("Informe o vencimento que esta no cartão de crédito.")
            return
        }
        this.setState({ state: "loading-credit" })
        const data = {
            cardName: this.state.cardName.trim(),
            cardNumber: this.state.cardNumber.trim(),
            cardDocument: this.state.cardDocument.trim(),
            cardCVV: this.state.cardCVV.trim(),
            note: {
                enabled: this.state.emitirNotaFiscal,
                document: this.state.noteDocument
            }
        }
        const result = await validateCreditCardById({ id: this.state.id, data: data, type: this.state.type })
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            this.setState({ state: "data-credit" })
            toastWarning(result.message)
        }
    }

    validateNoteFields = () => {
        if (this.state.emitirNotaFiscal) {
            if (isNullOrEmpty(this.state.noteDocument)) {
                toastWarning("Informe o documento para emissão de nota.")
                return false
            }
            if (!isValidDocument(this.state.noteDocument)) {
                toastWarning("Informe um documento válido para emissão de nota.")
                return false
            }
        }
        return true
    }

    cancelCharge = async () => {
        const confirm = window.confirm("Deseja mesmo cancelar este pedido de pagamento?")
        if (confirm) {
            const result = await deletePixById({ id: this.state.id, type: this.state.type })
            reloadWithAlert(result.message)
        }
    }

    render() {
        return (
            <MainContainer>
                {
                    this.state.note && <>
                        {
                            this.state.noteSettings.allPayments ? <>
                                <h4 className={this.state.status === "pending" ? "text-warning mt-3" : "text-danger mt-3"}>Emissão de nota fiscal</h4>
                                {
                                    this.state.noteSettings.changeDocument ? <>
                                        <div className="col-lg-4 col-12">
                                            <strong>CPF ou CNPJ</strong>
                                            <input type="text" onChange={(e) => this.setState({ noteDocument: e.target.value })} value={this.state.noteDocument} className="form-control" />
                                        </div>
                                        <small>Iremos emitir a nota fiscal para o documento informado.</small>
                                    </> : <>
                                        <strong>Documento: {takeIfIsNotNullOrEmpty(this.state.noteDocument) ?? "Não informado"} </strong><br />
                                        <small>Caso queira alterar o documento você deve entrar em contato com a administração do estabelecimento.</small>
                                    </>
                                }
                            </> : <>
                                <h4 className={this.state.status === "pending" ? "text-warning mt-3" : "text-danger mt-3"}>Deseja emitir nota fiscal?</h4>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="emitirNotaFiscal" checked={this.state.emitirNotaFiscal} onChange={(e) => this.setState({ emitirNotaFiscal: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="emitirNotaFiscal">
                                        Quero emitir nota fiscal
                                    </label>
                                </div>
                                {
                                    this.state.emitirNotaFiscal &&
                                    <>
                                        {this.state.noteSettings.changeDocument ? <>
                                            <div className="col-lg-4 col-12">
                                                <strong>CPF ou CNPJ</strong>
                                                <input type="text" onChange={(e) => this.setState({ noteDocument: e.target.value })} value={this.state.noteDocument} className="form-control" />
                                            </div>
                                            <small>Iremos emitir a nota fiscal para o documento informado.</small>
                                        </> : <>
                                            <strong>Documento: {takeIfIsNotNullOrEmpty(this.state.noteDocument) ?? "Não informado"} </strong><br />
                                            <small>Caso queira alterar o documento você deve entrar em contato com a administração do estabelecimento.</small>
                                        </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {
                    (this.state.pix || this.state.credit) &&
                    <>
                        {
                            this.state.state === "fill" &&
                            <div className="row">
                                <h4 className={this.state.status === "pending" ? "text-warning mt-3" : "text-danger mt-3"}>Escolha a forma de pagamento</h4>
                                {
                                    this.state.pix &&
                                    <div className="col-12 col-lg-3 mt-3">
                                        <button className={this.state.status === "pending" ? "btn btn-warning w-100 text-white" : "btn btn-danger w-100 text-white"} onClick={this.selectPixPaymentMethod}>
                                            <i className="fa-brands fa-pix mx-2" />
                                            Pix
                                        </button>
                                    </div>
                                }
                                {
                                    this.state.credit &&
                                    <div className="col-12 col-lg-3 mt-3">
                                        <button className={this.state.status === "pending" ? "btn btn-warning w-100 text-white" : "btn btn-danger w-100 text-white"} onClick={this.selectCreditPaymentMethod}>
                                            <i className="fas fa-credit-card mx-2" />
                                            Cartão de crédito
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.state.state === "loading-pix" &&
                            <div className="text-center">
                                <LoadingContainer />
                                <h5>Estamos gerando a chave de pagamento.</h5>
                            </div>
                        }
                        {
                            this.state.state === "data-pix" &&
                            <div className="row mt-3">
                                <h3><strong className={this.state.status === "pending" ? "text-warning" : "text-danger"}>Pagamento via Chave Pix</strong></h3>
                                <div className="col-12 mt-2">
                                    <img src={this.state.pixQRCode.QRCode} width="200" alt="QRCode" />
                                    <textarea disabled className="form-control text-center mt-2" rows="5">
                                        {this.state.pixQRCode.QRCodeText}
                                    </textarea>
                                </div>
                                <div className="mt-3">
                                    <button onClick={(e) => this.pixCopyPaste(this.state.pixQRCode.QRCodeText)} className={this.state.status === "pending" ? "btn btn-warning w-100 text-white" : "btn btn-danger w-100 text-white"}>
                                        <i className="fa-solid fa-copy mx-2" />
                                        Copiar chave
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    <button onClick={(e) => this.cancelCharge()} className={this.state.status === "pending" ? "btn btn-light" : "btn btn-light"}>
                                        <i className="fa-solid fa-cancel mx-2" />
                                        Cancelar
                                    </button>
                                </div>
                                <span className="text-center mt-2">Copie a chave Pix acima e faça o pagamento até {this.state.pixQRCode.expirationDateText} no seu banco de preferência.</span>
                            </div>
                        }
                        {
                            this.state.state === "loading-credit" &&
                            <div className="text-center">
                                <LoadingContainer />
                                <h5>Estamos processando o seu pagamento.</h5>
                            </div>
                        }
                        {
                            this.state.state === "data-credit" &&
                            <div className="row mt-3">
                                <h3><strong className={this.state.status === "pending" ? "text-warning" : "text-danger"}>Pagamento via cartão de crédito</strong></h3>
                                <div className="col-lg-4 col-12">
                                    <strong>Nome impresso no cartão</strong>
                                    <input type="text" onChange={(e) => this.setState({ cardName: e.target.value })} value={this.state.cardName && this.state.cardName} className="form-control" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <strong>CPF ou CNPJ</strong>
                                    <input type="text" onChange={(e) => this.setState({ cardDocument: e.target.value })} value={this.state.cardDocument && this.state.cardDocument} className="form-control" />
                                </div>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4 col-8">
                                            <strong>Número do cartão</strong>
                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cardNumber} onChange={(e) => this.setState({ cardNumber: e.target.value })} format="####.####.####.####" />
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <strong>CVV</strong>
                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cardCVV} onChange={(e) => this.setState({ cardCVV: e.target.value })} format="####" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <strong>Data de vencimento</strong>
                                    <NumberFormat autoComplete='off' className="form-control" value={this.state.cardDueDate} onChange={(e) => this.setState({ cardDueDate: e.target.value })} format="##/####" />
                                </div>
                                <div className="mt-3">
                                    <button onClick={this.confirmCreditPaymentMethod} className={this.state.status === "pending" ? "btn btn-warning w-100 text-white" : "btn btn-danger w-100 text-white"}>
                                        <i className="fas fa-credit-card mx-2" />
                                        Confirmar pagamento
                                    </button>
                                </div>
                                <div className="mt-3 text-center">
                                    <button onClick={(e) => this.setState({ state: "fill" })} className={this.state.status === "pending" ? "btn btn-light" : "btn btn-light"}>
                                        <i className="fa-solid fa-arrow-left mx-2" />
                                        Voltar
                                    </button>
                                </div>
                            </div>
                        }
                    </>
                }
            </MainContainer>)
    }
}

export { PaymentFlowView }