import { fetchFunction } from "../fetchFunction";

export const getContactInfoByTicketId = async ({ ticketId }) => {
    const options = {
        method: 'GET'
    }
    return await fetchFunction(`/form/detail/contact-info/${ticketId}`, options)
}

export const postContactInfoByTicketId = async ({ ticketId, data }) => {
    const options = {
        method: 'POST',
        body: JSON.stringify(data)
    }
    return await fetchFunction(`/form/detail/contact-info/${ticketId}`, options)
}