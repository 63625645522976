import Moment from 'moment';
import { fetchFunction } from './fetchFunction';

export const getInitializeTicket = async (ticket) => {
    const body = {
        parkId: ticket.estacionamentoId
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body)
    };
    return await fetchFunction("/payment/ticket-inicialize/" + ticket.id, options)
}

export const getRequestVehicleByTicket = async (ticket) => {
    const body = {
        id: ticket.id
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body)
    };
    return await fetchFunction("/publicTicket/request-vehicle", options)
}

export const getRequestVehicleEnabledByTicket = async (ticket) => {
    const body = {
        id: ticket.id
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body)
    };
    return await fetchFunction("/publicTicket/enabled/request-vehicle", options)
}

export const getTicketTotal = async (ticket, apiKey) => {
    const body = {
        endDate: Moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
        type: "NORMAL_DELIVERY",
        apiKey: apiKey
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body),
        apiKey: apiKey
    };
    return await fetchFunction("/payment/ticket-calculate/" + ticket.id, options)
}

export const getTicketProcessing = async (ticket, apiKey) => {
    const options = {
        method: 'GET',
        apiKey: apiKey
    };
    return await fetchFunction("/payment/ticket-processing/" + ticket.id, options)
}

export const postTicketPix = async (ticket, customer, apiKey) => {
    const body = {
        customer: customer
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body),
        apiKey: apiKey
    };
    return await fetchFunction("/payment/ticket-pix/" + ticket.id, options)
}

export const postTicketCreditCard = async (ticket, customer, card, apiKey) => {
    const body = {
        customer: customer,
        card: card
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(body),
        apiKey: apiKey
    };
    return await fetchFunction("/payment/ticket-credit-card/" + ticket.id, options)
}