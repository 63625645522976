import React from 'react'
import { getCurrentPark } from '../../config/storage/parkRepository'
// import { Toaster } from 'react-hot-toast';

class MainContainer extends React.Component {

    render() {
        return (
            <>
                {/* <Toaster /> */}
                {this.props.children}
            </>
        )
    }
}

class SystemContainer extends React.Component {

    render() {
        return (
            <div>
                {/* Desenvolvido por SelfParking */}
            </div>
        )
    }
}

class LoadingContainer extends React.Component {

    render() {
        return (
            <>
                <div>
                    <span className="loader" />
                    <h2>Aguarde...</h2>
                </div>
            </>
        )
    }
}

class ParkContainer extends React.Component {

    constructor(props) {
        super(props)
        const park = getCurrentPark()
        this.state = {
            park: park
        }
    }

    render() {
        return (
            <>
                <div>
                    <h2>{this.state.park?.name}</h2>
                    {
                        this.state.park?.address &&
                        <>
                            <small>{this.state.park?.address?.street}, {this.state.park?.address?.number}</small><br />
                        </>
                    }
                    <small>{this.state.park?.times}</small><br />
                    <small>{this.state.park?.document}</small><br />
                    <small>{this.state.park?.phone}</small>
                </div>
            </>
        )
    }
}

export { SystemContainer, LoadingContainer, ParkContainer, MainContainer }