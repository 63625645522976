import { getStorage, ref, getDownloadURL } from "firebase/storage";

export const getURLFromURI = async (uri) => {
    const cacheUrl = localStorage.getItem(uri)
    if (cacheUrl) {
        return cacheUrl
    }
    const storage = getStorage()
    const url = await getDownloadURL(ref(storage, uri))
    localStorage.setItem(uri, url)
    return url.toString()
}