
import { fetchFunction } from './fetchFunction'
import { getURLFromURI } from './filesWorker'

export const loadCurrentResale = async () => {
    const options = {
        method: "GET",
        headers: {
            'Cache-Control': 'public, max-age=300, s-maxage=600'
        }
    }
    const id = process.env.REACT_APP_REVENDA_ID ?? "yDDlIMYGASii6Scv1Un8"
    const resale = await fetchFunction(`/resele/reseleById/${id}`, options)
    if (resale.logoAdministrativo) {
        resale.logoAdministrativo = await getURLFromURI(resale.logoAdministrativo)
    }
    if (resale.logoPrinter) {
        resale.logoPrinter = await getURLFromURI(resale.logoPrinter)
    }
    localStorage.setItem('resale', JSON.stringify(resale))
    return resale
}

export const getCurrentResale = async () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    if (!resale) {
        return await loadCurrentResale()
    }
    return resale
}

export const getResaleLogoPrinter = () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    return resale?.logoAdministrativo ?? ""
}

export const getResaleName = () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    return resale?.nome ?? ""
}

export const getResaleColor = () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    return resale?.siteCor ?? ""
}

export const getResaleSite = () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    return resale?.site ?? ""
}

export const getResaleSiteTicket = () => {
    const resale = JSON.parse(localStorage.getItem('resale'))
    return resale?.siteTicket ?? ""
}