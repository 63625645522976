import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import TicketView from "../view/ticket_old/ticket";
import WelcomeView from "../view/welcome/index";
import { RegisterLoginView, RegisterView } from "../view/register";
import { MonthlyChargesView, MonthlyChargeView } from "../view/monthly";
import { AccreditedView } from "../view/accredited";
import { TicketDetailView } from "../view/ticket/detail";
import { TicketPaymentView } from "../view/ticket/payment";
import { ContactInfoView } from "../view/contact-info";
import { SingleNoteView } from "../view/note";
import { TicketSearchView } from "../view/ticket/search";
import { SubscriptionDetailView, SubscriptionView } from "../view/subscription";

const AllRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<TicketView />} />
            <Route exact path='/bem-vindo' element={<WelcomeView />} />
            <Route exact path='/ticket/visualizar' element={<TicketDetailView />} />
            <Route exact path='/ticket/pagar' element={<TicketPaymentView />} />
            <Route exact path='/ticket/buscar' element={<TicketSearchView />} />
            <Route exact path='/nota/visualizar' element={<SingleNoteView />} />
            <Route exact path='/contato/cadastrar' element={<ContactInfoView />} />
            <Route exact path='/formulario/cadastrar' element={<RegisterView />} />
            <Route exact path='/formulario/entrar' element={<RegisterLoginView />} />
            <Route exact path='/mensalista/cobranca' element={<MonthlyChargeView />} />
            <Route exact path='/credenciado/detalhes' element={<AccreditedView />} />
            <Route exact path='/mensalista/cobrancas' element={<MonthlyChargesView />} />
            <Route exact path='/assinatura/estabelecimento/detalhes' element={<SubscriptionDetailView />} />
            <Route exact path='/assinatura/estabelecimento' element={<SubscriptionView />} />
        </Routes>
    </BrowserRouter>
)

export default AllRoutes