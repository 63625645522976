
import React from 'react'
import { goToWindow, isValidatePlate, queryString, toastWarning } from '../../shared/utils'
import { LoadingContainer, MainContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { getContactInfoByTicketId, postContactInfoByTicketId } from '../../config/api/form/formContactInfo'
import { ParkCardView, ContactInfoCardView } from '../cards'
import { getURLFromURI } from '../../config/api/filesWorker'
import NumberFormat from 'react-number-format'

class ContactInfoView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            id: queryString("id"),
            plate: null,
            name: "",
            cellphone: "",
            document: "",
            email: "",
            park: null
        }
    }

    componentDidMount = async () => {
        const result = await getContactInfoByTicketId({ ticketId: this.state.id })
        this.setState({ state: "loading" })
        if (result.success) {
            this.setState({ ...result.data })
            await this.applyTheme({ park: result.data.park })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    saveData = async () => {
        const data = {
            plate: this.state.plate ?? this.state.ticket.plate,
            name: this.state.name,
            phone: this.state.cellphone,
            document: this.state.document,
            email: this.state.email
        }
        this.setState({ state: "loading" })
        const result = await postContactInfoByTicketId({ ticketId: this.state.id, data: data })
        if (result.success) {
            goToWindow(`/ticket/visualizar/?t=${this.state.ticket.ticket}&e=${this.state.ticket.parkId}&id=${this.state.ticket.id}&c=${Math.random()}`) 
        } else {
            toastWarning(result.message)
            this.setState({ state: "fill" })
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    <div className="container-md top-20">
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-center text-size-20'>DADOS DE CONTATO</div>
                                    <ParkCardView park={this.state.park} />
                                    {
                                        this.state.contactInfo &&
                                        <ContactInfoCardView contactInfo={this.state.contactInfo} />
                                    }
                                    {
                                        !isValidatePlate(this.state.ticket.plate) &&
                                        <div className="col-12">
                                            <strong>Placa do veículo ( obrigatório )</strong>
                                            <input type="text" onChange={(e) => this.setState({ plate: e.target.value })} value={this.state.plate} className="form-control" />
                                        </div>
                                    }
                                    <div className="col-12">
                                        <strong>Nome ( obrigatório )</strong>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                    <div className="col-12">
                                        <strong>Celular ( obrigatório )</strong>
                                        <NumberFormat className="form-control" mask='_' format={'(##)#####-####'} value={this.state.cellphone} onChange={(e) => this.setState({ cellphone: e.target.value })} />
                                    </div>
                                    <div className="col-12">
                                        <strong>Documento ( opcional )</strong>
                                        <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                    </div>
                                    <div className="col-12">
                                        <strong>E-mail ( opcional )</strong>
                                        <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                    </div>
                                    <div className="mt-3 text-center">
                                        Salvando os dados você esta de acordo com o nosso <a target="_blank" rel="noreferrer" href={this.state.park.settings.sitePrivacidade}><strong>Termo de Privacidade</strong></a>
                                    </div>
                                    <div className="col-lg-12 mt-2">
                                        {
                                            this.state.contactInfo ?
                                                <button onClick={(e) => this.saveData()} className="btn btn-success btn-lg w-100 text-white">
                                                    <i className="fas fa-edit mx-2" />
                                                    Editar dados de contato
                                                </button>
                                                :
                                                <button onClick={(e) => this.saveData()} className="btn btn-success btn-lg w-100 text-white">
                                                    <i className="fas fa-save mx-2" />
                                                    Salvar dados de contato
                                                </button>
                                        }
                                    </div>
                                    <div className="mt-3 text-center">
                                        <button onClick={(e) => window.history.back()} className="btn btn-light">
                                            <i className="fas fa-chevron-left mx-2" />
                                            Voltar
                                        </button>
                                    </div>
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

export { ContactInfoView }