import React from 'react'
import { getResaleColor } from '../../config/api/resaleService'
import { SystemContainer } from './system-container'

class TicketContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backgroundColor: getResaleColor()
        }
    }

    render() {
        return (
            <div style={{
                backgroundColor: this.state.backgroundColor,
                margin: "-2.1%",
                paddingTop: 25,
                paddingBottom: 20,
                textAlign: "center"
            }}>
                <div className="ticket">
                    {this.props.children}
                    <SystemContainer />
                </div>
            </div>
        )
    }
}

class RegisterContainer extends React.Component {

    render() {
        return (
            <div className="ticket-bigger">
                {this.props.children}
            </div>
        )
    }
}

class ImageContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            url: props.url
        }
    }

    render() {
        return (
            <>
                {
                    this.state.url &&
                    <div className='top-20 text-center'>
                        <img className='height-80' style={{ borderRadius: "5px" }} src={this.state.url} alt={"Imagem"} />
                    </div>
                }
            </>
        )
    }
}

export { TicketContainer, RegisterContainer, ImageContainer };