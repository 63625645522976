
import React from 'react'
import { goToWindow, isNullOrEmpty, isValidDocument, queryString, reloadWithAlert, toastSuccess, toastWarning } from '../../../shared/utils'
import { LoadingContainer, MainContainer } from '../../../shared/layout/system-container'
import { deletePixByTicketId, generatePixByTicket, getTicketByNumberAndParkId, validatePixByTicketId } from '../../../config/api/form/formTicket'
import { ImageContainer, RegisterContainer } from '../../../shared/layout/ticket-container'
import { getURLFromURI } from '../../../config/api/filesWorker'
import { TicketCardDetail, TicketResumeTotal } from '../../ticket_old/components/ticket-components'
import { ParkCardView } from '../../cards'

class TicketPaymentView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: "loading",
            park: null
        }
    }

    componentDidMount = async () => {
        const number = queryString("t")
        const parkId = queryString("e")
        const result = await getTicketByNumberAndParkId({ ticket: number, parkId: parkId })
        if (result.success) {
            this.setState({ ...result.data })
            await this.applyTheme({ park: result.data.park })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    applyTheme = async ({ park }) => {
        const url = await getURLFromURI(park.settings.logo)
        this.setState({ url: url })
        document.title = park.name
        document.body.style.backgroundColor = park.settings.backgroundColor
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", park.settings.backgroundColor);
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                    <div className="text-center">Estamos calculando o valor total do ticket.</div>
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-center text-size-20'>PAGAR TICKET</div>
                                    <ParkCardView park={this.state.park} />
                                    {
                                        this.state.ticket && <>
                                            <TicketCardDetail ticket={this.state.ticket} />
                                            <TicketResumeTotal total={this.state.total} />
                                            <TicketPaymentFlowView ticket={this.state.ticket} park={this.state.park} />
                                        </>
                                    }
                                </div>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
            </div>
        )
    }
}

class TicketPaymentFlowView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ticket: props.ticket,
            isNoteEnabled: props.park.flags.isNoteEnabled,
            emitirNotaFiscal: false,
            document: "",
            name: "",
            state: "fill"
        }
    }

    selectPixPaymentMethod = async () => {
        if (!this.validateNoteFields()) {
            return
        }
        this.setState({ state: "loading-pix" })
        const data = {
            id: this.state.ticket.id,
            type: "Pix", //No futuro tera cartão de crédito.
            note: {
                enabled: this.state.emitirNotaFiscal,
                name: this.state.name,
                document: this.state.document
            }
        }
        const result = await generatePixByTicket({ data: data })
        if (result.success) {
            this.setState({ pixQRCode: result.data })
            this.setState({ state: "data-pix" })
            this.verifyPixPayment()
        } else {
            reloadWithAlert(result.message)
        }
    }

    verifyPixPayment = async () => {
        await new Promise(resolve => setTimeout(resolve, 5000))
        const result = await validatePixByTicketId({ id: this.state.ticket.id })
        if (result.success) {
            if (result.data.status === "Pago e Entregue" || result.data.status === "Pago e Estacionado") {
                goToWindow(`/ticket/visualizar/?t=${this.state.ticket.ticket}&e=${this.state.ticket.parkId}&id=${this.state.ticket.id}&c=${Math.random()}`) 
            } else {
                this.verifyPixPayment()
            }
        } else {
            reloadWithAlert("Não foi possível verificar o pagamento.\nTente novamente.")
        }
    }

    validateNoteFields = () => {
        if (this.state.emitirNotaFiscal) {
            if (isNullOrEmpty(this.state.document)) {
                toastWarning("Informe o documento para emissão de nota.")
                return false
            }
            if (!isValidDocument(this.state.document)) {
                toastWarning("Informe um documento válido para emissão de nota.")
                return false
            }
        }
        return true
    }

    pixCopyPaste = (pixCopyPaste) => {
        navigator.clipboard.writeText(pixCopyPaste)
        toastSuccess("Pix Copia e Cola copiado com sucesso.")
    }

    cancelCharge = async () => {
        const confirm = window.confirm("Deseja mesmo cancelar este pedido de pagamento?")
        if (confirm) {
            const result = await deletePixByTicketId({ id: this.state.ticket.id })
            reloadWithAlert(result.message)
        }
    }

    render() {
        return (<>
            {
                this.state.state === "fill" &&
                <div>
                    {
                        this.state.isNoteEnabled &&
                        <div className="mb-3">
                            <h4>Deseja emitir nota fiscal?</h4>
                            <div className="form-check form-switch">
                                <input className="form-check-input" id="emitirNotaFiscal" checked={this.state.emitirNotaFiscal} onChange={(e) => this.setState({ emitirNotaFiscal: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="emitirNotaFiscal">
                                    Quero emitir nota fiscal
                                </label>
                            </div>
                            {
                                this.state.emitirNotaFiscal &&
                                <>
                                    <div className="col-12">
                                        <strong>Seu nome ( opcional )</strong>
                                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                    </div>
                                    <div className="col-12">
                                        <strong>CPF ou CNPJ</strong>
                                        <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                    </div>
                                    <small>Iremos emitir a nota fiscal para o documento informado.</small>
                                </>
                            }
                        </div>
                    }
                    <div className="col-lg-12">
                        <button className="btn btn-success w-100 btn-lg text-white" onClick={this.selectPixPaymentMethod}>
                            <i className="fa-brands fa-pix mx-2" />
                            Pagar com Pix
                        </button>
                        <div className="mt-3 text-center">
                            <button onClick={(e) => window.history.back()} className="btn btn-light">
                                <i className="fas fa-chevron-left mx-2" />
                                Voltar
                            </button>
                        </div>
                    </div>
                </div>
            }
            {
                this.state.state === "loading-pix" &&
                <div className="text-center">
                    <LoadingContainer />
                    <h5>Estamos gerando a chave de pagamento.</h5>
                </div>
            }
            {
                this.state.state === "data-pix" &&
                <div className="mt-3 text-center">
                    <h4>Pagamento via Chave Pix</h4>
                    <div className="col-12 mt-2">
                        <img src={this.state.pixQRCode.QRCode} width="200" alt="QRCode" />
                        <textarea disabled className="form-control text-center mt-2" rows="5">
                            {this.state.pixQRCode.QRCodeText}
                        </textarea>
                    </div>
                    <div className="mt-3">
                        <button onClick={(e) => this.pixCopyPaste(this.state.pixQRCode.QRCodeText)} className="btn btn-success w-100 btn-lg text-white">
                            <i className="fa-solid fa-copy mx-2" />
                            Copiar chave
                        </button>
                    </div>
                    <div className="mt-3 text-center">
                        <button onClick={(e) => this.cancelCharge()} className="btn btn-light">
                            <i className="fa-solid fa-cancel mx-2" />
                            Cancelar
                        </button>
                    </div>
                    <span className="text-center mt-2">Copie a chave Pix acima e faça o pagamento até {this.state.pixQRCode.expirationDateText} no seu banco de preferência.</span>
                </div>
            }
        </>)
    }
}

export { TicketPaymentView }