import { doc, getDoc, addDoc, collection } from "firebase/firestore";
import { firestore } from '../config/firestore'

const _trackEvent = async (ticket, event, type) => {
    const docRef = doc(firestore, "clientes", ticket.clienteId);
    const docSnap = await getDoc(docRef);
    let cliente = docSnap.data()

    await addDoc(collection(firestore, "analytics"), {
        type: type,
        screen: "Site de Tickets",
        event: event,
        estacionamentoId: ticket.estacionamentoId,
        estacionamentoNome: ticket.estacionamentoNome,
        clienteId: docSnap.id,
        clienteNome: cliente.nome,
        origin: 'site-ticket',
        user: {
            email: "",
            nome: ticket.id
        },
        createdAt: new Date()
    })
}

export const sendScreenView = (ticket, event) => {
    _trackEvent(ticket, event, "screenView")
}

export const sendClickButton = (ticket, button) => {
    _trackEvent(ticket, button, "click")
}